<template>
  <!-- <div class="admin"> -->
  <div class="login">
    <a-card hoverable:true style="margin: 0 auto; border-radius: 10px; width: 900px; padding-left: 0">
      <div class="login_card">
        <div class="login_card_left">
          <img style="width: 77%; margin-left: 15%; margin-top: 19%" src="../../assets/admin_login/index_left.png" />
        </div>
        <div class="login_card_right">
          <div style="margin-left: 30%; margin-top: 10%">
            <img style="width: 30%" src="../../assets/admin_login/yidaoyunlogo.png" />
          </div>
          <div style="margin: 5% auto">
            <p style="font-weight: 900; font-size: 20px; align: center; margin-left: 15%">江苏一道云科技发展有限公司</p>
          </div>

          <a-form style="margin-left: 0" :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-input placeholder="请输入账号" v-decorator="['account', { rules: [{ required: true, message: '请输入账号' }], initialValue: '' }]">
                <a-icon slot="prefix" type="user" />
              </a-input>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-input-password placeholder="请输入密码" v-decorator="['pwd', { rules: [{ required: true, message: '请输入密码' }], initialValue: '' }]">
                <a-icon slot="prefix" type="lock" />
              </a-input-password>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" style="width: 260px" html-type="submit"> 登录 </a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
    </a-card>
  </div>

  <!-- </div> -->
</template>

<script>
export default {
  data() {
    return {
      form: this.$form.createForm(this, { name: 'coordinated' }),
      message: 'hello world',
    };
  },
  created() {},
  methods: {
    handleSubmit(e) {
      var that = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //   console.log('Received values of form: ', values);
          that.axios.post('/admin/login', values).then((req) => {
            if (req.data.code == 200) {
              that.$message.success('登录成功');
              localStorage.setItem('adminToken', req.data.admin.token);
              localStorage.setItem('adminId', req.data.admin.id);
              this.store.admin.isLogin=true
              that.$router.push({
                name: 'dashboard',
                params: {
                  name: '----', //测试参数传递
                },
              });
            } else {
              that.$message.error(req.data.error);
            }
          });
        }
      });
    },
  },
};
</script>
<style>
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  lang="less" scoped>
.login {
  /* margin: 0 auto; */
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/admin_login/background.png');
  background-size: 100% 100%;
  // z-index: -100px;
  padding: 100px;
  //    .ant-card-body {
  //     padding: 0;
  //     box-shadow: 2px 2px 5px #888888;
  // }
}
.login_card {
  width: 1100px;
  height: 440px;
  // margin: 0 auto;
  display: flex;
}
.login_card_left {
  border-radius: 10px 0 0 10px;
  width: 45%;
  background-color: #f6f5fb;
}
.login_card_right {
  // margin-left: 5%;
  width: 40%;
  border-radius: 10px;
  // height: 441px;
  // background-color: #f6f5fb;
}
// }
</style>
